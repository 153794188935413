import React, { useState } from "react";
import "./PlayRoom.css";
import playbtn from "../../images/playroom/playbtn.png";
import stopbtn from "../../images/playroom/stopbtn.png";
import inbound1 from "../../images/homeaudio/inbound1.mp3";
import inbound2 from "../../images/homeaudio/inbound2.mp3";
import outbound1 from "../../images/homeaudio/outbound1.mp3";
import outbound2 from "../../images/homeaudio/outbound2.mp3";
import btmbar from "../../images/testimonial/btm-bar.png";
import btmbarshort from "../../images/testimonial/btm-barshort.png"
const PlayRoom = () => {
  const inbound = [
    {
      text: "Return Policy",
      imgplay: playbtn,
      imgstop: stopbtn,
      audio: inbound1,
    },
    {
      text: "Order Confirmation",
      imgplay: playbtn,
      imgstop: stopbtn,
      audio: inbound2,
    },
  ];
  const outbound = [
    {
      text: "Final Expense",
      imgplay: playbtn,
      imgstop: stopbtn,
      // audio: outbound1,
    },
    {
      text: "Medi-Care",
      imgplay: playbtn,
      imgstop: stopbtn,
      audio: outbound2,
    },
  ];
  const [btnIndex, setBtnIndex] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeLineIndex, setActiveLineIndex] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexOutbound, setActiveIndexOutbound] = useState(null);
  const [activeAudioElement, setActiveAudioElement] = useState(null);

  // Define the sequence of play button lines
  const playButtonLines = Array.from({ length: 20 }, (_, i) => `line${i + 1}`);

  const startAnimation = (index) => {
    setActiveIndex(index);
    setIsPlaying(true);
    setActiveLineIndex(-1);

    // Stop the previous audio if it is playing
    if (activeAudioElement) {
      activeAudioElement.pause();
      activeAudioElement.currentTime = 0;

      // If the same audio is clicked again, reset the active audio element and return
      if (index === activeIndex) {
        setActiveAudioElement(null);
        setActiveIndex(null);
        return;
      }
    }

    const intervalId = setInterval(() => {
      setActiveLineIndex((index) => {
        if (index >= playButtonLines.length - 1) {
          clearInterval(intervalId);
          setIsPlaying(false);
        }
        return index + 1;
      });
    }, 1000);

    // Play the new audio
    const audioElement = new Audio(inbound[index].audio);
    audioElement.setAttribute("id", "inbound-audio"); // Set a unique ID for the audio element
    audioElement.play();
    setActiveAudioElement(audioElement); // Set the active audio element
  };
  const startAnimationOutbound = (index) => {
    setActiveIndexOutbound(index);
    setIsPlaying(true);
    setActiveLineIndex(-1);

    // Stop the previous audio if it is playing
    if (activeAudioElement) {
      activeAudioElement.pause();
      activeAudioElement.currentTime = 0;

      // If the same audio is clicked again, reset the active audio element and return
      if (index === activeIndexOutbound) {
        setActiveAudioElement(null);
        setActiveIndexOutbound(null);
        return;
      }
    }

    const intervalId = setInterval(() => {
      setActiveLineIndex((index) => {
        if (index >= playButtonLines.length - 1) {
          clearInterval(intervalId);
          setIsPlaying(false);
        }
        return index + 1;
      });
    }, 1000);

    // Play the new audio
    const audioElement = new Audio(outbound[index].audio);
    audioElement.setAttribute("id", "outbound-audio"); // Set a unique ID for the audio element
    audioElement.play();
    setActiveAudioElement(audioElement); // Set the active audio element
  };

  return (
    <>
      <div className="playroom-wrapper">
        <h3>Callwise.ai Playroom</h3>
        <p className="playroom-f-p">
          Callwise provides amazing capabilities when it comes to automating
          conversations with customers. With our advanced AI and machine
          learning algorithms, Callwise.ai can handle numerous scenarios easily,
          provide personalized and efficient customer service, and collect
          valuable data to improve products and services.
        </p>
        <div className="play-wise-btn-flex web-d-none-btn">
          <button
            className={btnIndex == 1 ? "btn-play-wise active" : "btn-play-wise"}
            onClick={() => setBtnIndex(1)}
          >
            Inbound calls
          </button>
          <button
            className={btnIndex == 2 ? "btn-play-wise active" : "btn-play-wise"}
            onClick={() => setBtnIndex(2)}
          >
            Outbound calls
          </button>
        </div>
        <div className="playroom-flex">
          {btnIndex == 1 ? (
            <div className="playroom-flex-left">
              {inbound.map((data, index) => (
                <div className="play-btn-wrapper" key={index}>
                  <p>{data.text}</p>
                  <div
                    className={
                      index === activeIndex
                        ? "flex-play-btn-l-d active"
                        : "flex-play-btn-l-d"
                    }
                  >
                    <div
                      className="ply-btn-bg"
                      onClick={() => startAnimation(index)}
                    >
                      <img
                        src={index != activeIndex ? data.imgplay : data.imgstop}
                      />
                    </div>
                    <div className="play-btn-lines-main">
                      {playButtonLines.map((line, i) => (
                        <div
                          key={line}
                          className={`play-btn-lines ${line} ${activeIndex === index && i <= activeLineIndex
                            ? "active"
                            : "inactive"
                            }`}
                          style={{ transitionDelay: `${(i + 1) * 10}ms` }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="playroom-flex-left">
              {outbound.map((data, index) => (
                <div className="play-btn-wrapper" key={index}>
                  <p>{data.text}</p>
                  <div
                    className={
                      index === activeIndexOutbound
                        ? "flex-play-btn-l-d active"
                        : "flex-play-btn-l-d"
                    }
                  >
                    <div
                      className="ply-btn-bg"
                      onClick={() => startAnimationOutbound(index)}
                    >
                      <img
                        src={
                          index != activeIndexOutbound
                            ? data.imgplay
                            : data.imgstop
                        }
                      />
                    </div>
                    <div className="play-btn-lines-main">
                      {playButtonLines.map((line, i) => (
                        <div
                          key={line}
                          className={`play-btn-lines ${line} ${activeIndexOutbound === index &&
                            i <= activeLineIndex
                            ? "active"
                            : "inactive"
                            }`}
                          style={{ transitionDelay: `${(i + 1) * 10}ms` }}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {btnIndex == 1 ? (
            <div className="playroom-flex-right">
              <div className="play-wise-btn-flex mob-d-none-btn">
                <button
                  className={
                    btnIndex == 1 ? "btn-play-wise active" : "btn-play-wise"
                  }
                  onClick={() => setBtnIndex(1)}
                >
                  Inbound calls
                </button>
                <button
                  className={
                    btnIndex == 2 ? "btn-play-wise active" : "btn-play-wise"
                  }
                  onClick={() => setBtnIndex(2)}
                >
                  Outbound calls
                </button>
              </div>
              <p className="playroom-flex-right-f-p">
                Say goodbye to disgruntled customers and missed calls, and welcome customer satisfaction like never before.
              </p>

              <ul className="list-playroom">
                <li>24/7 Customer Service</li>
                <li>Instant Response</li>
                <li>Smart Troubleshooting</li>
                <li>Customer-Centric</li>
                <li>Missed Call? Not Anymore</li>
              </ul>
            </div>
          ) : (
            <div className="playroom-flex-right">
              <div className="play-wise-btn-flex mob-d-none-btn">
                <button
                  className={
                    btnIndex == 1 ? "btn-play-wise active" : "btn-play-wise"
                  }
                  onClick={() => setBtnIndex(1)}
                >
                  Inbound calls
                </button>
                <button
                  className={
                    btnIndex == 2 ? "btn-play-wise active" : "btn-play-wise"
                  }
                  onClick={() => setBtnIndex(2)}
                >
                  Outbound calls
                </button>
              </div>
              <p className="playroom-flex-right-f-p">
                Experience a surge in efficiency, productivity, and results with Callwise.ai’s automated outbound calls.
              </p>

              <ul className="list-playroom">
                <li>Efficient Tele-Marketing</li>
                <li>Engaging Conversations</li>
                <li>Timely Insurance Renewals</li>
                <li>Reliable Payment Reminders</li>
                <li>Increased Productivity</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <img src={btmbar} alt="star" className="test-btmbr" />
      <img src={btmbarshort} className="short-btmbr" />
    </>
  );
};

export default PlayRoom;
