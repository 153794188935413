import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import './demo.css';
import DemoScreen from './DemoScreen';
import InputSection from './InputSection';
import ThankyouScreen from './ThankyouScreen';
import InputFields from './InputFields';

const Demo = () => {
    const [currentScreen, setCurrentScreen] = useState('inputSection'); // Start with InputSection

    // Handle the transition to ThankyouScreen after 1 minute in DemoScreen
    useEffect(() => {
        if (currentScreen === 'demoScreen') {
            const timer = setTimeout(() => {
                setCurrentScreen('thankyouScreen');
            }, 60000); // 1 minute

            return () => clearTimeout(timer); // Cleanup the timer on component unmount or screen change
        }
    }, [currentScreen]);

    const handleTryDemoClick = () => {
        setCurrentScreen('inputFields');
    };

    const handleStartDemoClick = () => {
        setCurrentScreen('demoScreen');
    };

    const handleExitDemoClick = () => {
        setCurrentScreen('thankyouScreen');
    };

    return (
        <>
            <div className='main-outer-div' style={{ paddingTop: '15%', paddingBottom: '5%' }}>
                <Box className='flex flex-col items-center justify-center max-w-4xl mx-auto main-outer mt-10'>
                    <Box className='flex flex-col items-center justify-center main-content'>
                        <Typography className='heading-demo' sx={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: "Work Sans",
                            fontSize: '128px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '111.891px', /* 120% */
                        }}>
                            100,000
                        </Typography>
                        <Typography className='sub-heading-demo' sx={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: "Work Sans",
                            fontSize: '48px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '56px', /* 120% */
                        }}>
                            Sales and Service Reps, Ready When You Are
                        </Typography>
                        <Typography className='description-demo' sx={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: "Work Sans",
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '24px', /* 120% */
                            marginTop: '20px'
                        }}>
                            Introducing the world's first ever AI that can have full on 10-40 minute long phone calls that sound like a REAL human, with infinite memory, perfect recall, and can autonomously take actions across 5,000 plus applications. It can do the entire job of a full time agent without having to be trained, managed or motivated. It just works 24/7, all year round.
                        </Typography>
                    </Box>
                </Box>

                {/* Conditionally render screens based on state */}
                {currentScreen === 'inputSection' && <InputSection onTryDemoClick={handleTryDemoClick} />}
                {currentScreen === 'inputFields' && <InputFields onStartDemoClick={handleStartDemoClick} />}
                {currentScreen === 'demoScreen' && <DemoScreen onExitDemoClick={handleExitDemoClick} />}
                {currentScreen === 'thankyouScreen' && <ThankyouScreen />}
            </div >
        </>
    );
};

export default Demo;
