import React from 'react'
import reportingGraph from '../../../images/CallTracking/reporting-graph.png'
import reportingGraphHover from '../../../images/CallTracking/reporting-hover.png'
const Reporting = () => {
    return (
        <>
            <div className='reporting-main'>
                <div className='reporting-gradient' />
                <div className='reporting-left'>
                    <img src={reportingGraph} alt='' className="default-image" />
                    <img src={reportingGraphHover} alt='' className="hover-image" />
                </div>
                <div className='reporting-right'>
                    <div className='reporting-right-context'>
                        <h2>
                            Real-Time Reporting and Analytic.
                        </h2>
                        <p>
                            Filter, group, sort, search, and drill into your data how and when you want with immediate results. Our reporting out-performs any competing product on the market.
                        </p>
                    </div>
                    <button>
                        Schedule a Live Demo
                    </button>
                </div>
            </div>
        </>
    )
}

export default Reporting
