import React from 'react'
import './CallTracking.css'
import TrackingHero from './Components/TrackingHero'
import GraphSection from './Components/GraphSection'
import FrameWorkSection from './Components/FrameWorkSection'
import Reporting from './Components/Reporting'
import SeamLess from './Components/SeamLess'
import Plans from './Components/Plans'
import BottomSec from './Components/BottomSec'

const CallTracking = () => {
    return (
        <>
            <div className='callTracking'>
                <TrackingHero />

                <GraphSection />

                <FrameWorkSection />

                <Reporting />

                <SeamLess />

                <Plans />

                <BottomSec />
            </div>
        </>
    )
}

export default CallTracking
