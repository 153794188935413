import { Typography } from '@mui/material'
import React from 'react'
import './demo.css'
const ThankyouScreen = () => {
    return (
        <>
            <div className='thankyou-outer mt-0 items-center'>
                <Typography className='statement'>
                    Thank you for trying the demo! For the full services please contact on the information below.
                </Typography>
                <Typography className='mail'>
                    johndoe19@email.com
                </Typography>
                <Typography className='contact-no'>
                    063-27483-27392
                </Typography>
            </div>
        </>
    )
}

export default ThankyouScreen
