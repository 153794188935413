import React from 'react'

const BottomSec = () => {
    return (
        <>
            <div className='bottom-main'>
                <div className='bottom-bg'/>
                <div className='bottom-context'>
                    <h2>
                        Ready to Transform Your Calls into Results?
                    </h2>
                    <p>
                        Join the Callwise revolution now.
                    </p>
                    <button>
                        Get Your Custom Quote
                    </button>
                </div>
            </div>
        </>
    )
}

export default BottomSec
