import React, { useState } from 'react';
import './Cloud.css'
function ServicesComp({ index, title, description, imageSrc, isSelected, onSelect, onHover }) {
    const [isExpanded, setIsExpanded] = useState(index === 0);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsExpanded(true);
        setIsHovered(true);
        onHover(index)
    };

    const handleMouseLeave = () => {

        setIsHovered(false);
    };

    return (
        <div
            className={`point ${isSelected ? 'selected' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ maxHeight: isSelected ? '145px' : '64px' }}
        >
            <div className='point-heading'>
                <img src={imageSrc} alt="" />
                <h1>{title}</h1>
            </div>
            {isExpanded && isSelected && <p>{description}</p>}
        </div>
    );
}

export default ServicesComp;
