import React, { useState } from "react";
import { useEffect, useRef } from 'react';
import { Button, Modal } from "react-bootstrap";
import thankyouvector from '../../images/thankyouvector.svg'
import sademo from "../../images/sademo.png";
import "./homebanner.css";
import closemodal from "../../images/closemodal.png";
import "react-phone-number-input/style.css";
import CountrySelector from '../Country Selector/CountrySelector'
import callwiselogo from '../../images/callwise.png'
import trophy from '../../images/trophy.png'
import effectleft from '../../images/effect-left.png'
import effectright from '../../images/effect-right.png'
import idraklogo from '../../images/idraklogo.png'
import rcci from '../../images/rcci.png'
import awards from '../../images/awards.png'
const HomeBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal3, setshowModal3] = useState(false);


  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleShowModal3 = () => setshowModal3(true)
  const handleCloseModal3 = () => setshowModal3(false)

  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    country: '',
    desk: '',
    phoneNumber: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Reset error and status for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form is valid ')
      setShowModal(false);
      setshowModal3(true);
      const config = {
        SecureToken: '55c37aa3-9b8d-4773-9e02-be1801bb19d8',
        To: 'info@callwise.ai',
        From: "info@callwise.ai",
        Subject: 'Demo Scheduled',
        Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.company}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               What calls do you look forward to automate? : ${formData.desk}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    return errors;
  };

  return (
    <>
      <div className="homebanner-p" id="home-page">
        <div className="homebanner-child-text">
          <h1>
            Add more <span >$s</span> to your <span >Net Profit</span>

          </h1>

          <h4>50% Savings | 100% Efficiency | Triple Performance</h4>
          <p>
            With CallWise, every conversation becomes an opportunity for profits. Whether you're an insurance carrier or a fintech company, our SaaS-based solution is tailored to meet your specific needs.
          </p>
          <Button type="button" className="black-btn" onClick={handleShowModal}>
            Schedule a Demo
          </Button>
        </div>
      </div>
      <div className="award">
        <div className="context">
          <div className="context-left">
            <img src={callwiselogo} />
            <p>Callwise crowned <span style={{ color: '#F2CB18' }}>'Best Innovation'</span> in <span style={{ color: '#F2CB18' }}>Artificial
              intelligence</span> at International Achievement
              Awards 2023 in Cairo-Egypt.</p>
          </div>
          <div className="context-right">
            <img src={trophy} />
            <img src={effectleft} className="effectleft" />
            <img src={effectright} className="effectright" />
          </div>
        </div>
        <div className="logos">
          <div className="logos-left">
            <p>A Project by</p>
            <img src={idraklogo} />
          </div>
          <div className="logos-right">
            <div className='logos-right-left'>
              <div>
                <img src={rcci} />
              </div>
              <div>
                <a href='https://www.idrakai.com/'>www.idrakai.com |&nbsp;</a>
              </div>
            </div>
            <div className="logos-right-right">
              <div>
                <img src={awards} />
              </div>
              <div>
                <a href='https://callwise.ai/'>www.callwise.ai</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div>
        <Modal
          className="modal-banner-wrapper"
          show={showModal}
          onHide={handleCloseModal}
          id='demo-modal'
        >
          <Modal.Header>
            <img src={closemodal} onClick={handleCloseModal} />
          </Modal.Header>
          <Modal.Body>
            <div className="modaldollor">
              <img className="sademo-img-modal" src={sademo} />
              <h4>Do you want to </h4>
              <h3>Schedule a demo? </h3>
            </div>

            <form ref={form} onSubmit={handleSubmit}>
              {errors.name && <span className="error">{errors.name}</span>}
              <input
                placeholder='Name*'
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
              <input
                placeholder='Email*'
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.company && <span className="error">{errors.company}</span>}
              <input
                placeholder='Company Name'
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
              {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
              <div className="d-flex" >
                <CountrySelector setData={setFormData} data={formData} />

                <input
                  placeholder='Phone Number*'
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  inputMode="numeric"

                  required
                />
              </div>

              {errors.desk && <span className="error">{errors.desk}</span>}
              <input
                placeholder='What type of calls are you looking forward to automate'
                type="text"
                name="desk"
                value={formData.desk}
                onChange={handleChange}
              />

              <div className="btn-mdl-wrapper">
                <Button className="btn-mdl-red" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={showModal3} onHide={handleCloseModal3} className="submit-modal">
          <div className="submit-modal-content">
            <img src={thankyouvector} />
            <p>Thank you for your submission. We'll be in touch soon!</p>
          </div>
        </Modal>
      </div>

    </>
  );
};

export default HomeBanner;
