import React, { useState, useEffect } from 'react';

const ImageSequence = ({ image1Src, image2Src }) => {
  const [imagesVisible, setImagesVisible] = useState([true, false]);

  useEffect(() => {
    const showImagesWithLoop = () => {
      setImagesVisible(prevState => {
        const newState = [...prevState];
        newState[1] = !newState[1];
        return newState;
      });
    };

    const intervalId = setInterval(showImagesWithLoop, 1500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="automating-right-right">
      <img
        src={image1Src}
        alt="automating"
        className={imagesVisible[0] ? 'fade-in' : ''}
      />
      <img
        src={image2Src}
        alt=""
        className={imagesVisible[1] ? 'fade-in' : 'hidden'}
      />
    </div>
  );
};

export default ImageSequence;
