import React from "react";
import "./home.css";
import HomeBanner from "../../components/homebanner/HomeBanner";
import HomeBrands from "../../components/HomeBrands/HomeBrands";
import Finance from "../../components/finance/Finance";
import UseAi from "../../components/UseAi/UseAi";
import PlayRoom from "../../components/PlayRoom/PlayRoom";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <Finance />
      <HomeBrands />
      <UseAi />
      <PlayRoom />

    </>
  );
};

export default Home;
