import React from 'react'
import './Cloud.css'
import infinte from '../../images/Cloud/infinite.png'
import infinitemob from '../../images/Cloud/infinitemob.png'
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import round from '../../images/Cloud/round the clock.png'
import data from '../../images/Cloud/dataprotection.png'
import expenses from '../../images/Cloud/expenses.png'
import { useRef } from 'react';
import ServicesComp from './ServicesComp';
import agentpoint from '../../images/Cloud/agentpoint.png'
import cheappoint from '../../images/Cloud/cheappoint.svg'
import recordingpoint from '../../images/Cloud/recordingpoint.svg'
import aileadpoint from '../../images/Cloud/AIleadpoint.svg'
import countriespoint from '../../images/Cloud/countriespoint.svg'
import closemodal from "../../images/closemodal.png";
import CountrySelector from '../Country Selector/CountrySelector'


import agent from '../../images/Cloud/agent.png'
import cheap from '../../images/Cloud/cheapvoid.png'
import recording from '../../images/Cloud/recording.png'
import ailead from '../../images/Cloud/AIlead.png'
import countries from '../../images/Cloud/countries.png'


import callrouting from '../../images/Cloud/callrouting.svg'
import Analytics from '../../images/Cloud/Analytics.svg'
import Support from '../../images/Cloud/Support.svg'
import CRM from '../../images/Cloud/CRM.svg'
import Ticket from '../../images/Cloud/Ticket.svg'
import Language from '../../images/Cloud/Language.svg'

import check from '../../images/Cloud/check.svg'

import happiness from '../../images/Cloud/happiness.png'
import plug from '../../images/Cloud/Plug.png'
import scale from '../../images/Cloud/Scale.png'
import expensessixth from '../../images/Cloud/expenses6.png'
import supportsixth from '../../images/Cloud/support24.png'
import datadriven from '../../images/Cloud/datadriven.png'

import sugar from '../../images/Cloud/sugar.png'
import pipedrive from '../../images/Cloud/pipedrive.png'
import hubspot from '../../images/Cloud/hubspot.png'
import kustomer from '../../images/Cloud/kustomer.png'
import salesforce from '../../images/Cloud/salesforce.png'
import insightly from '../../images/Cloud/insightly.png'
import Slider from 'react-slick';
import { CustomPrevArrow } from '../CustomArrows/CustomPrevArrow';
import thankyouvector from '../../images/thankyouvector.svg'
import CustomNextArrow from '../CustomArrows/CustomNextArrow';

const Cloud = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModal3, setshowModal3] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal3 = () => setshowModal3(false)


    const cards = [
        {
            imgSrc: round,
            heading: 'Round-the-clock Support',
            para: "Day or night, our dedicated support team is always here for you. With extensive expertise in call center solutions, we're ready to tackle any challenge you encounter."
        },
        {
            imgSrc: data,
            heading: "Data Protection",
            para: "Keep Your Data Safe and Secure. We make sure only you can get to your information with strong security checks. Your data stays safe and private, just as it should be."
        },
        {
            imgSrc: expenses,
            heading: 'Cut Expenses, Boost Sales',
            para: "Boost your sales and save money with our easy-on-the-pocket call center software. Known for its top-notch quality, it'll make your business run smoother and stand out in the crowd."
        }
    ]

    const sliderRef = useRef();

    const pointsData = [
        {
            imageSrc: agentpoint,
            title: 'Agent Status Board',
            description: "Stay on top of your team's performance with real-time updates. Our Agent Status Board lets you monitor and manage your team's productivity with just a glance. Keep your finger on the pulse of your operations and ensure your customers always get top-tier service."
        },
        {
            imageSrc: cheappoint,
            title: 'Cheapest VOIP',
            description: 'Get the best rates without compromising on quality. Our VOIP solutions are not only cost-effective but also backed by superior technology, ensuring that your call center operates smoothly and economically. Invest your savings back into growing your business. ',
        },
        {
            imageSrc: recordingpoint,
            title: 'Recording Portal',
            description: 'Never miss a beat with easy access to all your call recordings. Our Recording Portal organizes and secures your call data so you can review conversations, ensure compliance, and use the insights to improve customer interactions. ',
        },
        {
            imageSrc: aileadpoint,
            title: 'AI Lead Generator',
            description: " Transform the way you find new customers. Our AI Lead Generator harnesses the power of artificial intelligence to filter through data, predict patterns, and deliver hot leads directly to your team. It's lead generation, but not as you know it — it's smarter, faster, and always working for you.",
        },
        {
            imageSrc: countriespoint,
            title: 'Countries we are providing VoIP.',
            description: "UK, South Africa, Australia, Nigeria, Malaysia, USA."
        },
    ];
    const images = [
        {
            imageSrc: agent
        },
        {
            imageSrc: cheap
        },
        {
            imageSrc: recording
        },
        {
            imageSrc: ailead
        },
        {
            imageSrc: countries
        }
    ]


    const grid = [
        {
            imgSrc: callrouting,
            heading: 'Intelligent Call Routing',
            paragraph: 'Say goodbye to misdirected calls. Our smart routing guides customers to the right agent from the get-go, ensuring every call starts on the right foot.'
        },
        {
            imgSrc: Analytics,
            heading: 'Real-time Analytics',
            paragraph: 'Make informed decisions with up-to-the-minute analytics. Track call trends, agent performance, and customer satisfaction as it happens. '
        },
        {
            imgSrc: Support,
            heading: 'Multichannel Support',
            paragraph: 'Engage with your customers where they are. Our platform unifies voice, email, chat, and social media interactions into a single, streamlined flow.'
        },
        {
            imgSrc: CRM,
            heading: 'CRM Integration',
            paragraph: 'Your customer information, perfectly in sync. Effortlessly integrate with your existing CRM system for a unified approach to customer management.'
        },
        {
            imgSrc: Ticket,
            heading: 'Automated Ticketing',
            paragraph: 'Turn issues into tickets automatically, organizing customer concerns and ensuring no query is left unanswered.'
        },
        {
            imgSrc: Language,
            heading: 'Natural Language Processing',
            paragraph: 'Engage in more natural conversations with customers thanks to our Natural Language Processing, making every interaction more human and less robotic. '
        },

    ]


    const callsoutbound = [
        {
            imgSrc: check,
            heading: 'Predictive Dialler',
            paragraph: "Our smart dialer anticipates when agents will be free and dials numbers ahead of time, so they're always on a call, boosting efficiency like never before."
        },
        {
            imgSrc: check,
            heading: 'Progressive Dialler',
            paragraph: "Calls are placed at the perfect pace, aligning with agent availability for smoother operation and no more awkward waits for your customers."
        },
        {
            imgSrc: check,
            heading: 'Power Dialler',
            paragraph: "Say goodbye to manual dialing. Our automated system connects agents to the next call instantly, keeping productivity at its peak."
        },
        {
            imgSrc: check,
            heading: 'AI Dialler',
            paragraph: "Streamline your outreach with automated messages for appointments, reminders, and campaigns, giving your team the freedom to focus on more complex tasks."
        },
    ]

    const callsinbound = [
        {
            imgSrc: check,
            heading: 'Skill-Based Routing',
            paragraph: "Watch your customer satisfaction soar as calls are automatically directed to the agent best suited to handle them, based on their skills and expertise."
        },
        {
            imgSrc: check,
            heading: 'ACD (Automatic Call Distributor)',
            paragraph: "Efficiently manage incoming calls and route them to the appropriate department or agent with our intelligent call distribution system."
        },
        {
            imgSrc: check,
            heading: 'IVR (Interactive Voice Response)',
            paragraph: "Empower your customers with self-service options, and free up your agents for more complex queries with our customizable IVR solutions."
        },
    ]

    const sixthsectiongrid = [
        {
            imgSrc: happiness,
            heading: 'Customer Happiness, Redefined',
            paragraph: 'Watch satisfaction soar as you connect with customers seamlessly, offering them the help they need exactly when they need it.'
        },
        {
            imgSrc: plug,
            heading: 'Productivity, Supercharged',
            paragraph: 'Empower your agents with tools that streamline their workflow, letting them focus on what they do best—wowing customers.'
        },
        {
            imgSrc: scale,
            heading: 'Scale Like a Pro',
            paragraph: 'As your business grows, our VOIP Hub flexes with you. No growing pains, just smooth sailing from startup to enterprise.'
        },
        {
            imgSrc: expensessixth,
            heading: 'Slim Down Your Expenses',
            paragraph: 'Say goodbye to budget bloat. Our affordable solution means you spend less money on calls and more on growing your business.'
        },
        {
            imgSrc: supportsixth,
            heading: 'Support That Never Sleeps',
            paragraph: 'Have a question at 3 a.m.? No problem. Our support heroes are on standby 24/7, ready to rescue you from any sticky situation.'
        },
        {
            imgSrc: datadriven,
            heading: 'Data-Driven Brilliance',
            paragraph: 'With predictive analytics, make smarter business decisions that are backed by data, not guesswork. Better insights lead to better outcomes.'
        },
    ]

    const seventhlogos = [
        {
            imgSrc: sugar,
        },
        {
            imgSrc: pipedrive,
        },
        {
            imgSrc: hubspot,
        },
        {
            imgSrc: kustomer,
        },
        {
            imgSrc: salesforce,
        },
        {
            imgSrc: insightly,
        }
    ]
    const [outbound, setOutbound] = useState('Outbound')

    const handleOutbound = (calltype) => {
        setOutbound(calltype)
    }


    const [selectedIdx, setSelectedIdx] = useState(0);
    const handleHover = (index) => {
        setSelectedIdx(index);
        sliderRef.current.slickGoTo(index);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const form = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        country: '',
        desk: '',
        phoneNumber: ''
    });

    const [errors, setErrors] = useState({});
    const handleCloseModal = () => setShowModal(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        // Reset error and status for the field being edited
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(Object.keys(validationErrors).length)
        if (Object.keys(validationErrors).length === 0) {
            console.log('Form is valid ')
            setShowModal(false);
            setshowModal3(true);
            const config = {
                SecureToken: '55c37aa3-9b8d-4773-9e02-be1801bb19d8',
                To: 'info@callwise.ai',
                From: "info@callwise.ai",
                Subject: 'Demo Scheduled',
                Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.company}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               What calls do you look forward to automate? : ${formData.desk}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
        } else {
            setErrors(validationErrors);
        }
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.name || data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters*';
        }

        if (!data.email) {
            errors.email = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid email format*';
        }

        if (!data.country) {
            errors.country = 'Country is required*';
        }

        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone number is required*';
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number format';
        }

        return errors;
    };

    return (
        <>
            <div className='cloud-first-section-main'>
                <div className='first-section-left'>
                    <h1>VOIP that Brings You Closer <br />
                        to Your Customers!</h1>
                    <p>Our comprehensive <span>inbound</span> and <span>outbound</span> solutions are your ticket to robust sales and dynamic customer connections.</p>

                    <Button className='cloud-button' onClick={handleShowModal}>
                        Schedule a Live Demo
                    </Button>
                </div>
                <div className='first-section-right'>
                    <img src={infinte} alt='' className='infinite' />
                    <img src={infinitemob} alt='' className='infinitemob' />
                </div>
            </div>



            <div className='cloud-second-section-main'>
                <div className='second-context'>
                    <h1>What makes Callwise different from others?</h1>
                    <p>Designed to be the backbone of your customer communication strategy, our platform empowers your business with intelligent customer experience management. Whether your focus is on spreading your sales wings with outbound calls or nurturing customer relationships with inbound support, our solution stands at the ready.</p>
                </div>
                <div className='second-cards-web'>
                    {cards.map((cards, index) => (
                        <div className='card-main' key={index}>
                            <img src={cards.imgSrc} alt='' />
                            <h2>{cards.heading}</h2>
                            <p>{cards.para}</p>
                        </div>
                    ))}
                </div>
                <div className='second-cards-mob'>
                    <Slider {...settings}>
                        {cards.map((cards, index) => (
                            <div className='card-main' key={index}>
                                <img src={cards.imgSrc} alt='' className='card-top-image' />
                                <h2>{cards.heading}</h2>
                                <p>{cards.para}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className='second-section-button'>
                    <Button className='cloud-button' onClick={handleShowModal}>
                        Get Your Custom Quote
                    </Button>
                </div>
            </div>



            <div className='cloud-third-section-main'>
                <div className='third-context'>
                    <h1>VOIP Services - Inbound and Outbound Calls</h1>
                    <p>Connect and Communicate with Clarity. Our VOIP services are the heartbeat of modern call center operations, offering unparalleled clarity and reliability for both inbound and outbound communications. They enable vibrant conversations, bridge distances, and power your business with the ability to connect with anyone, anywhere.
                    </p>
                </div>
                <div className='third-points'>
                    <div className='third-points-left'>
                        {pointsData.map((data, index) => (
                            <ServicesComp
                                key={index}
                                index={index}
                                imageSrc={data.imageSrc}
                                title={data.title}
                                description={data.description}
                                isSelected={index === selectedIdx}
                                onSelect={() => setSelectedIdx(index)}
                                onHover={handleHover}

                            />
                        ))}
                    </div>
                    <div className='third-points-right'>
                        <img src={images[selectedIdx].imageSrc} alt="" />
                    </div>
                </div>
            </div>


            <div className='cloud-fourth-section-main'>
                <div className='fourth-section-context'>
                    <h1>Rich Communication Tools</h1>
                    <p>Power Up Your Customer Interactions</p>
                </div>
                <div className='fourth-section-grid'>
                    {grid.map((data, index) => (
                        <div className='grid-card' key={index}>
                            <img src={data.imgSrc} alt='' />
                            <h1>{data.heading}</h1>
                            <p>{data.paragraph}</p>
                        </div>
                    ))}
                </div>
                <div className='fourth-section-mob'>
                    <Slider {...settings}>
                        {grid.map((data, index) => (
                            <div className='grid-card' key={index}>
                                <img src={data.imgSrc} alt='' />
                                <h1>{data.heading}</h1>
                                <p>{data.paragraph}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>


            <div className='cloud-fifth-section-main'>
                <div className='fifth-section-context'>
                    <h1>Callwise is best for managing inbound & outbound call!</h1>
                    <p>Unleash Calling Potential–Smart, Responsive Service!</p>
                </div>
                <div className='fifth-buttons'>
                    <button onClick={() => handleOutbound('Outbound')} style={{ background: outbound === 'Outbound' ? '#E31E25' : 'black', color: outbound === 'Outbound' ? 'white' : 'white' }}>
                        OUTBOUND CALLS
                    </button>
                    <button onClick={() => handleOutbound('Inbound')} style={{ background: outbound === 'Inbound' ? '#E31E25' : 'black', color: outbound === 'Inbound' ? 'white' : 'white' }}>
                        INBOUND CALLS
                    </button>
                </div>
                {outbound === 'Outbound' && (
                    <div className='fifth-context'>
                        {callsoutbound.map((data, index) => (
                            <div className='fifth-context-subsection'>
                                <div className='fifth-context-heading'>
                                    <img src={data.imgSrc} alt='' />
                                    <h1>{data.heading}</h1>
                                </div>
                                <p>{data.paragraph}</p>
                            </div>
                        ))}
                    </div>
                )}
                {outbound === 'Inbound' && (
                    <div className='fifth-context'>
                        {callsinbound.map((data, index) => (
                            <div className='fifth-context-subsection'>
                                <div className='fifth-context-heading'>
                                    <img src={data.imgSrc} alt='' />
                                    <h1>{data.heading}</h1>
                                </div>
                                <p>{data.paragraph}</p>
                            </div>
                        ))}
                    </div>
                )}
                <div className='custom-qoute'>
                    <Button className='cloud-button' onClick={handleShowModal}>Get Your Custom Quote</Button>
                </div>
            </div>

            <div className='cloud-sixth-section-main'>
                <div className='sixth-section-context'>
                    <h1>Elevate Your Call Game with DialSmart VOIP Hub</h1>
                    <p>The Smart Way to Connect</p>
                </div>
                <div className='sixth-section-grid'>
                    {sixthsectiongrid.map((data, index) => (
                        <div className='grid-card-sixth' key={index}>
                            <img src={data.imgSrc} alt='' />
                            <h1>{data.heading}</h1>
                            <p>{data.paragraph}</p>
                        </div>
                    ))}
                </div>
                <div className='sixth-section-mob'>
                    <Slider {...settings}>
                        {sixthsectiongrid.map((data, index) => (
                            <div className='grid-card-sixth' key={index}>
                                <img src={data.imgSrc} alt='' />
                                <h1>{data.heading}</h1>
                                <p>{data.paragraph}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            <div className='cloud-seventh-section-main'>
                <div className='sixth-section-context'>
                    <h1>CRM Integrations</h1>
                    <p>Callwise can integrate with your favourite CRM</p>
                </div>
                <div className='seventh-logos'>
                    {seventhlogos.map((data, index) => (
                        <img src={data.imgSrc} alt='' key={index} />
                    ))}
                </div>
            </div>

            <Modal
                className="modal-banner-wrapper"
                show={showModal}
                onHide={handleCloseModal}
                id='demo-modal'
            >
                <Modal.Header>
                    <img src={closemodal} onClick={handleCloseModal} alt='' />
                </Modal.Header>
                <Modal.Body>
                    <div className="modaldollor">
                        <h4>Get your Custom Qoute</h4>
                    </div>

                    <form ref={form} onSubmit={handleSubmit}>
                        {errors.name && <span className="error">{errors.name}</span>}
                        <input
                            placeholder='Name*'
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                        <input
                            placeholder='Email*'
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {errors.company && <span className="error">{errors.company}</span>}
                        <input
                            placeholder='Company Name'
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                        />
                        {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                        <div className="d-flex" >
                            <CountrySelector setData={setFormData} data={formData} />

                            <input
                                placeholder='Phone Number*'
                                type="text"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                inputMode="numeric"

                                required
                            />
                        </div>

                        {errors.desk && <span className="error">{errors.desk}</span>}
                        <input
                            placeholder='What type of calls are you looking forward to automate'
                            type="text"
                            name="desk"
                            value={formData.desk}
                            onChange={handleChange}
                        />

                        <div className="btn-mdl-wrapper">
                            <Button className="btn-mdl-red" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showModal3} onHide={handleCloseModal3} className="submit-modal">
                <div className="submit-modal-content">
                    <img src={thankyouvector} />
                    <h1>Thank you!</h1>
                    <p>Your submission has been sent.</p>
                </div>
            </Modal>
        </>
    )
}

export default Cloud
