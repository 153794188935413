import React, { useEffect } from "react";
import "./HomeBrands.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import s1 from "../../images/homebrands/s1.png";
import s2 from "../../images/homebrands/s2.png";
import s3 from "../../images/homebrands/s3.png";
import s4 from "../../images/homebrands/s4.png";
import s5 from "../../images/homebrands/s5.png";
import autobot from '../../images/autobot.png'
import glowautobot from '../../images/glowautobot.png'
import hello from '../../images/hello.png'
import AOS from "aos";
import "aos/dist/aos.css";
import CustomNextArrow from "../CustomArrows/CustomNextArrow";
import { CustomPrevArrow } from "..//CustomArrows/CustomPrevArrow";
import ImageSequence from "../ImageSequence/ImageSequence";

const sliderbrands = [
  {
    img: s1,
  },
  {
    img: s2,
  },
  {
    img: s3,
  },
  {
    img: s4,
  },
  {
    img: s5,
  },
  {
    img: s1,
  },
  {
    img: s2,
  },
  {
    img: s3,
  },
  {
    img: s4,
  },
  {
    img: s5,
  },
  {
    img: s1,
  },
  {
    img: s2,
  },
  {
    img: s3,
  },
  {
    img: s4,
  },
];

const HomeBrands = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="automating-p">
        <div className="brands-home-p">
          <h2>
            {" "}
            World’s Leading Brands Trust Callwise To Deliver Exceptional
            Experiences
          </h2>
          <Slider {...settings}>
            {sliderbrands.map((data, index) => (
              <div className="slider-brads-c-d" key={index}>
                <div className="img-brands-flex">
                  <img src={data.img} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="automating-flex">
          <div className="automating-left">
            <h2 data-aos="fade-up">AUTOMATING BEYOND</h2>
            <h3 data-aos="fade-up">
              <span>1 MILLION</span> CALLS DAILY
            </h3>
            <p data-aos="fade-up">
              Accelerating voice automation from days to minutes by providing a
              scalable time saving and cost efficient alternative.
            </p>
          </div>
          <div className="automating-right">
            <img src={hello} className='helloimage'data-aos='fade-down'/>
            <div><ImageSequence image1Src={autobot} image2Src={glowautobot}/></div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default HomeBrands;
