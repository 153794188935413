import React from 'react'
import WaveAnimation from './WaveAnimation'
const TrackingHero = () => {
    return (
        <>
            <div className='herosec-main'>
                <div className='hero-gradient'/>
                <div className='herosec-first'>
                    <div className='hero-ellipse' />
                    <p> Leading Call Tracking Tool</p>
                </div>
                <div className='herosec-second'>
                    <h2>
                        Turn Conversations Into <br /><span>Conversions!</span>
                    </h2>
                    <p>
                        Maximize the value of every caller with our game-changing inbound call tracking software.
                    </p>
                </div>
                <div className='herosec-buttons'>
                    <button className='signup'> Sign Up </button>
                    <button className='getADemo'> Get A Demo </button>
                </div>
                <div style={{ width: '100%' }}>
                    {/* <WaveAnimation /> */}
                </div>

            </div>
        </>
    )
}

export default TrackingHero
