import React, { useEffect, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Finance.css";
import g1 from "../../images/finace/g1.png";
import g2 from "../../images/finace/g2.png";
import g3 from "../../images/finace/g3.png";
import g4 from "../../images/finace/g4.png";
import g5 from "../../images/finace/g5.png";
import { Link, useLocation } from "react-router-dom";
import CustomNextArrow from "../CustomArrows/CustomNextArrow";
import { CustomPrevArrow } from "..//CustomArrows/CustomPrevArrow";


const Finance = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const location = useLocation();

  useEffect(() => {
    console.log('useEffect is being executed.');
  
    if (location.hash) {
      console.log('Hash detected:', location.hash);
  
      const element = document.getElementById(location.hash);
      if (element) {
        console.log('Scrolling to element:', element);
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  const images = [
    {
      heading: "Finance",
      img: g1,
      colorBg: "#C1C5E4",
      url:'/finance?tab=0'
    },
    {
      heading: "Insurance",
      img: g2,
      colorBg: "#CBE1C7",
      url:'/finance?tab=1'
    },
    {
      heading: "Ecommerce & Retail",
      img: g3,
      colorBg: "#F5D291",
      url:'/finance?tab=2'
    },
    {
      heading: "Logistic & Supply Chain",
      img: g4,
      colorBg: "#DFEFFA",
      url:'/finance?tab=3'
    },
    {
      heading: "Restaurant",
      img: g5 ,
      colorBg: "#F0B9AC",
      url:'/finance?tab=4'
    },
  ];

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // You can adjust the breakpoint (768) based on your design
    };
    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      <div className="UseAi-main finace-p-top-h" id="UseAi-main">
        <h2>INDUSTRIES</h2>
        <p>
          Transform your Business and Unlock new Streams of Revenue.
          Callwise is the Perfect fit for these industries and Beyond.
        </p>
      </div>
      {isMobileView ? (
        <div className="UseAi-slider">
        <Slider {...settings}dots={true}>
          {images.map((data, index) => {
           return <div className="finance-p-grid">
            <div
            className="finance-c-d"
            key={index}
            style={{ backgroundColor: data.colorBg }}
            >
              <h3>{data.heading}</h3>
              <a href={data.url}>
                {" "}
                <img src={data.img} alt={data.heading} />
              </a>
            </div>
           </div>
            }
        )}
        </Slider>
        </div>
      ) : (
        <div className="finace-p-grid">
          {images.map((data, index) => (
            <div
              className="finance-c-d"
              key={index}
              style={{ backgroundColor: data.colorBg }}
            >
              <h3>{data.heading}</h3>
              <a href={data.url}>
                {" "}
                <img src={data.img} alt={data.heading} />
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Finance;
