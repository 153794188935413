import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../../components/homebanner/homebanner.css";

import ASR from '../../images/ASR.png'
import closemodal from "../../images/closemodal.png";
import sademo from "../../images/sademo.png";
import "react-phone-number-input/style.css";
import "./Features.css";

import Transcripting from "../../images/Features/Transcripting.png";
import btmbar from "../../images/testimonial/btm-bar.png";
import analytics from "../../images/Features/analytics.png";
import Conversational from "../../images/Features/Conversational.png";
import Automation from "../../images/Features/Automation.png";
import like from "../../images/Features/like.png";
import dislike from "../../images/Features/dislike.png";
import tick from "../../images/Features/tick.png";
import cross from "../../images/Features/cross.png";
import case1 from "../../images/Features/case1.png";
import case2 from "../../images/Features/case2.png";
import case3 from "../../images/Features/case3.png";
import uk from "../../images/Features/uk.png";
import pak from "../../images/Features/pak.png";
import france from "../../images/Features/france.png";
import spain from "../../images/Features/spain.png";
import italy from "../../images/Features/italy.png";
import china from "../../images/Features/china.png";
import poland from "../../images/Features/poland.png";
import arab from "../../images/Features/arab.png";
import CountrySelector from "../../components/Country Selector/CountrySelector";
import btmbarshort from "../../images/testimonial/btm-barshort.png"
const Features = () => {
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  function handleOnChange(value) {
    setPhoneNumber(value);
  }

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const language = [
    {
      image: uk,
      name: "English",
    },
    {
      image: pak,
      name: "Urdu",
    },
    {
      image: france,
      name: "French",
    },
    {
      image: spain,
      name: "Spanish",
    },
    {
      image: italy,
      name: "Italian",
    },
    {
      image: china,
      name: "Chinese",
    },
    {
      image: poland,
      name: "Polish",
    },
    {
      image: arab,
      name: "Arabic",
    },
  ];
  return (
    <>
      <div className="features-wrapper">
        <div className="indusfinance-wrapper homebanner-p" id="featurestop">
          <div className="finance-main-top-flex">
            <div className="finance-main-top-flex-left">
              <img src={ASR}/>
            </div>
            <div className="finance-main-top-flex-right">
              <h3>
              <span style={{fontSize:'40px', fontWeight:'700'}}>ASR as a Service!</span><br />
              Bring Clarity to Conversations!
              </h3>
              <p>
              Imagine having a tool that allows you to analyze customer interactions at a granular level, identifying pain points and opportunities. That's the power of end-to-end contact center solution “ASR As a Service”, and you have an unbeatable formula for boosting efficiency, increasing CSAT scores, and transforming your customer interactions.
              </p>

              <Button className="sad-idus" onClick={handleShowModal}>
                Schedule a demo
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="f-use-cases-wrapper">
        <div className="inbound-outbound-wrapper">
          <h3>
            {" "}
            Don't Let a Single Word Go Unnoticed
          </h3>
          <p className="h-poi h-poi-b-line">
          When it comes to customer interactions, every word is a world of insight. <br />
          Our ASR as a Service ensures that not a single word goes unnoticed.
          </p>
        </div>

        <div className="f-use-cases-flex">
          <div className="f-use-cases-inner">
            <img src={Transcripting} />
            <p>Insightful <br />Transcriptions</p>
          </div>
          <div className="f-use-cases-inner">
            <img src={analytics} />
            <p>Analytical <br />Speech </p>
          </div>
          <div className="f-use-cases-inner">
            <img src={Conversational} />
            <p>Conversational <br />Excellence </p>
          </div>
          <div className="f-use-cases-inner">
            <img src={Automation} />
            <p>Contact <br />Automation</p>
          </div>
        </div>
      </div>

      <img src={btmbar} alt="star" className="feature-test-btmbr" />
      <img src={btmbarshort} className="feature-short-btmbr"/>

      <div className="feature-m-s-wrapper">
        <div className="inbound-outbound-wrapper features-inbound-outbound-wrapper">
          <h3>CALLWISE ASR vs. Traditional Call Centers</h3>
          <p className="h-poi">Transcribe, Transform, Thrive with Callwise</p>
        </div>

        <div className="feature-m-s-flex">
          <div className="feature-m-s-flex-left">
            <div className="feature-m-s-flex-like">
              <p>CALLWISE ASR</p>
              <img src={like} />
            </div>
            <div className="feature-m-s-flex-left-tic-wrapper">
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Superior 95%+ Accuracy</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Ultra-Fast Response</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Versatile Deployment Options</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Cost-Effective Per-Port Pricing</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Data-Driven Fine-Tuning</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Customer-Centric Adaptation</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={tick} />
                <p>Industry-Specific Models</p>
              </div>
            </div>
          </div>
          <div className="feature-m-s-flex-right">
            <div className="feature-m-s-flex-dislike">
              <p>Traditional Call Centers</p>
              <img src={dislike} />
            </div>
            <div className="feature-m-s-flex-left-tic-wrapper">
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>Low Accuracy </p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>Higher latency </p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>No on-premise or private cloud support</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>No control over accuracy or latency</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>One model fits all</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>Don’t allow Fine-tuning</p>
              </div>
              <div className="feature-m-s-flex-left-tic-flex">
                <img src={cross} />
                <p>High Cost (Pay per API or pay per minute) </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="use-cases-features-wrapper">
        <div className="inbound-outbound-wrapper">
          <h3> TECHNOLOGY DEEP TECH Ai CAPABILITIES</h3>
          <p className="h-poi h-poi-b-line">
          Deep tech AI understanding enables our ASR service to adapt to your customer's language <br />and tone, providing a truly personalized and effective communication experience.
          </p>
        </div>

        <div className="use-cases-inner-div-f-flex">
          <div className="inner-use-cases-d case1">
            <div className="img-use-cases-f-inner">
              <img src={case1} />
            </div>
            <p>
              <span>95%+</span> speech
              <br /> to text accuracy
            </p>
          </div>
          <div className="inner-use-cases-d case2">
            <div className="img-use-cases-f-inner">
              <img src={case2} />
            </div>
            <p>
              <span>30</span> milliseconds
              <br />
              transcription speed
            </p>
          </div>
          <div className="inner-use-cases-d case3">
            <div className="img-use-cases-f-inner">
              <img src={case3} />
            </div>
            <p>
              STT & TTS
              <br />
              (ETE processing)
            </p>
          </div>
        </div>
      </div>

      <div className="suppported-language-btn">
        <div className="inbound-outbound-wrapper line-b-hr-btn">
          <h3>SUPPORTED LANGUAGES</h3>
        </div>

        <div className="bg-supported-language">
          <div className="flex-languages">
            {language.map((data, index) => (
              <div className="flex-lang-inner" key={index}>
                <img src={data.image} />
                <p>{data.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* modal */}
      <Modal
        className="modal-banner-wrapper"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <img src={closemodal} onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="modaldollor">
            <img className="sademo-img-modal" src={sademo} />
            <h4>Do you want to </h4>
            <h3>Schedule a demo </h3>
          </div>

          <form>
            <input type="text" name="name" placeholder="Name" />

            <br />

            <input type="email" name="email" placeholder="Email" />
            <br />
            <input type="text" name="company" placeholder="Company Name" />

            <div>
              <CountrySelector/>
            </div>

            <br />
            <div className="btn-mdl-wrapper">
              <Button className="btn-mdl-red" type="submit">
                Send
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Features;
