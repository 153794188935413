import { Typography } from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import audioSvg from './audio.svg';
import callwise from './callwiselogo.png';
import person from './person.png';

const DemoScreen = ({ onExitDemoClick }) => {
    return (
        <>
            <div className='max-w-4xl mx-auto my-10 demo-screen-main'>
                <Typography className='exit-demo cursor-pointer' onClick={onExitDemoClick}>
                    <ArrowBackIcon />
                    Exit Demo
                </Typography>
                <div className='inputSection-outer mt-0'>
                    <div className='flex items-end gap-5 input-fields-demo'>
                        <img src={callwise} alt='' />
                        <div className='input-demo'>
                            <img src={audioSvg} alt='' />
                            <Typography className='audio-text'>
                                Welcome to callwise!
                            </Typography>
                        </div>
                    </div>
                    <div className='flex items-end gap-5 justify-end w-full input-fields-demo-below'>
                        <div className='input-demo'>
                            <img src={audioSvg} alt='' />
                            <Typography className='audio-text'>
                                Tell me more details
                            </Typography>
                        </div>
                        <img src={person} alt='' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoScreen;
