import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import country from './country.json'

const CountrySelector = ({data, setData}) => {
  const countriesData = {
    countries: country,
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    // Set the default selected option
    setSelectedCountry({ value: 'United States', label: '+1' });

  }, []);

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setData((prevData) => ({
      ...prevData,
      country: selectedOption
    }));
  };



  const countryOptions = countriesData.countries.map((country) => ({
    value: `${country.name}, ${country.phoneCode}`, 
    label: (
      <div>
        <img
          src={require(`./flags/${country.image}.svg`)}

          alt={country.name}
          style={{ width: '10px', marginLeft: '10px' }}
        />
        &nbsp;&nbsp;&nbsp;
        {`(${country.phoneCode})`}
        
      </div>
    ),
  }));


  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  return (

    <>     
     <Select
        id="countrySelect"
        placeholder='+1'
        name='countrycode'
        options={countryOptions}
        value={data.country}
        onChange={handleCountrySelect}
      />
    {errors.country && <span className="error">{errors.country}</span>}
    </>

  );
};

export default CountrySelector;
