import React from 'react'

const PlanCard = ({ imgSrc, packageName, para, price, per, bulletHeading, index, saving }) => {

    const cardtwo = index === 2;

    return (
        <>
            <div className={`plan-card ${cardtwo ? 'card-special' : ''}`}>
                <div className='plancard-header'>
                    <img src={imgSrc} alt='' />
                    <h3>
                        {packageName}
                    </h3>
                    <p>
                        {para}
                    </p>
                    <div className='header-priceTag'>
                        <h2>
                            {price}
                        </h2>
                        <span>
                            {per}
                        </span>
                    </div>
                    <h4>{saving}</h4>
                </div>
                <div className='card-stroke' />

                <div className='plancard-bullets'>
                    {bulletHeading.map((bulletHeading, index) => {
                        return (
                            <div className='plancard-bullet-inner' key={index}>
                                <h3>{bulletHeading}</h3>
                                <p>$0.004/Minute</p>
                            </div>
                        );
                    })}
                </div>
                <div className='plancard-button'>
                    <button>Get Started</button>
                </div>
            </div>
        </>
    )
}

export default PlanCard
