import React, { useState } from "react";
import "./UseAi.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import A1 from "../../images/UseAi/A1.png";
import A2 from "../../images/UseAi/A2.png";
import A3 from "../../images/UseAi/A3.png";
import A4 from "../../images/UseAi/A4.png";
import A5 from "../../images/UseAi/A5.png";
import A6 from "../../images/UseAi/A6.png";
import A7 from "../../images/UseAi/A7.png";
import A11 from "../../images/UseAi/A11.png";
import A22 from "../../images/UseAi/A22.png";
import A33 from "../../images/UseAi/A33.png";
import A44 from "../../images/UseAi/A44.png";
import A55 from "../../images/UseAi/A55.png";
import A66 from "../../images/UseAi/A66.png";
import A77 from "../../images/UseAi/A77.png";
import CustomNextArrow from "../CustomArrows/CustomNextArrow";
import { CustomPrevArrow } from "..//CustomArrows/CustomPrevArrow";

const UseAi = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const images = [
    {
      heading: "+200%",
      para: "Growth Potential",
      img: A1,
      imghover: A11,
    },
    {
      heading: "50%",
      para: "Operational Cost Reduction",
      img: A2,
      imghover: A22,
    },
    {
      heading: "24/7",
      para: "Availability",
      img: A3,
      imghover: A33,
    },
    {
      heading: '+60%',
      para: "Data-Driven Decisions",
      img: A4,
      imghover: A44,
    },
    {
      heading: "90%",
      para: "Customer Satisfaction",
      img: A5,
      imghover: A55,
    },
    {
      heading: "-50%",
      para: "Manual Work",
      img: A6,
      imghover: A66,
    },
    {
      heading:'95%',
      para: "Precision and Accuracy",
      img: A7,
      imghover: A77,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="UseAi-main" id="UseAi-main">
        <h2>
           Why CallWise?
        </h2>
        <p>
        Because It’s high time your calls work Smarter not Harder! From handling inquiries to 
        resolving issues, Callwise ensures consistent and reliable support, 24/7.

        </p>
      </div>

      <div className="grid-useai">
        <Slider {...settings}dots={true}>
          {images.map((data, index) => (
            <div
              className="useai-c-d"
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="useai-img-d">
                <img
                  src={index === hoveredIndex ? data.imghover : data.img}
                  alt=""
                />
              </div>
              <div>
                <h3><b>{data.heading}</b></h3>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default UseAi;
