import { Button, TextField } from '@mui/material';
import React from 'react';

const InputFields = ({ onStartDemoClick }) => {
    return (
        <>
            <div className='inputSection-outer'>
                <div className='input-demo-fields'>
                    <label>Email Address</label>
                    <input className='email-field-demo' />
                </div>
                <div className='input-demo-fields'>
                    <label>Contact No.</label>
                    <input className='email-field-demo' />
                </div>
            </div>
            <div className='inputSection-outer-button'>
                <Button className='demo-button' sx={{
                    color: '#fff'
                }} onClick={onStartDemoClick}>
                    Start Demo
                </Button>
            </div>
        </>
    )
}

export default InputFields;
