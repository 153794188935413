import React from "react";
import nextarrow from "../../images/industries-finance/nextarrow.png";
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "gray",
        borderRadius: "50%",
        height: "30px",
        width: "30px",
        padding: "5px",
      }}
      onClick={onClick}
    >
      <img className="next-prev-arrow" src={nextarrow} alt="arrows" />
    </div>
  );
};

export default CustomNextArrow;
