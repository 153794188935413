import React from 'react';
import audioSvg from './audio.svg';
import { Button, Typography } from '@mui/material';

const InputSection = ({ onTryDemoClick }) => {
    return (
        <>
            <div className='inputSection-outer'>
                <div className='input-demo'>
                    <img src={audioSvg} alt='' />
                    <Typography className='audio-text'>
                        Welcome to callwise!
                    </Typography>
                </div>
                <div className='input-demo'>
                    <img src={audioSvg} alt='' />
                    <Typography className='audio-text'>
                        Would you like a free demo?
                    </Typography>
                </div>
            </div>
            <div className='input-button-div'>
                <Typography className='input-text'>
                    Would you like a free demo?
                </Typography>
                <Button className='input-inner-button' sx={{
                    color: '#fff'
                }} onClick={onTryDemoClick}>
                    Try Demo
                </Button>
            </div>
        </>
    )
}

export default InputSection;
