import React, { useRef, useState } from "react";
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../components/homebanner/homebanner.css";
import thankyouvector from '../../images/thankyouvector.svg'
import closemodal from "../../images/closemodal.png";
import sademo from "../../images/sademo.png";
import "react-phone-number-input/style.css";
import CountrySelector from "../../components/Country Selector/CountrySelector";
import "./Finance.css";
import outbound1 from "../../images/industries-finance/outbound-images1.png";
import outbound2 from "../../images/industries-finance/outbound-images2.png";
import outbound3 from "../../images/industries-finance/outbound-images3.png";
import inbound1 from "../../images/industries-finance/inbound-images1.png";
import inbound2 from "../../images/industries-finance/inbound-images2.png";
import inbound3 from "../../images/industries-finance/inbound-images3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import c1 from "../../images/industries-finance/usecases/c1.png";
import c2 from "../../images/industries-finance/usecases/c2.png";
import c3 from "../../images/industries-finance/usecases/c3.png";
import c4 from "../../images/industries-finance/usecases/c4.png";
import c5 from "../../images/industries-finance/usecases/c5.png";
import c6 from "../../images/industries-finance/usecases/c6.png";
import c7 from "../../images/industries-finance/usecases/c7.png";
import c8 from "../../images/industries-finance/usecases/c8.png";
import stop from "../../images/industries-finance/stop-btn.png";
import play from "../../images/industries-finance/ply-btn.png";
import leftmock from "../../images/industries-finance/leftmock.png";
import rightmock from "../../images/industries-finance/rightmock.png";
import CustomNextArrow from "../../components/CustomArrows/CustomNextArrow";
import { CustomPrevArrow } from "../../components/CustomArrows/CustomPrevArrow";
import mobmock from "../../images/industries-finance/mobmockup.png";
import contactcentre from '../../images/24-7 Contact centre.png'

import uk from "../../images/Features/uk.png";
import pak from "../../images/Features/pak.png";
import france from "../../images/Features/france.png";
import spain from "../../images/Features/spain.png";
import italy from "../../images/Features/italy.png";
import china from "../../images/Features/china.png";
import poland from "../../images/Features/poland.png";
import arab from "../../images/Features/arab.png";

const Finance = () => {

  const navigate = useNavigate();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    console.log(query.get('tab'), 'kdlskdldks')
  }, [query])

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (query.get('tab')) {
      SetTabsMob(query.get('tab'));
      const element = document.getElementById('tab');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }


  }, [query]);

  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [showModal3, setshowModal3] = useState(false);
  const handleCloseModal3 = () => setshowModal3(false)
  const form = useRef();
  function handleOnChange(value) {
    setPhoneNumber(value);
  }

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (event) => {
    setShowModal(true);
    setshowModal3(false);
  }
  const [counter, SetCounter] = useState(0);
  const [tabsMob, SetTabsMob] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [autoplayVideos, setAutoplayVideos] = useState(false);

  const sliderRef = useRef();

  useEffect(() => {

  })

  const language = [
    {
      image: uk,
      name: "English",
    },
    {
      image: pak,
      name: "Urdu",
    },
    {
      image: france,
      name: "French",
    },
    {
      image: spain,
      name: "Spanish",
    },
    {
      image: italy,
      name: "Italian",
    },
    {
      image: china,
      name: "Chinese",
    },
    {
      image: poland,
      name: "Polish",
    },
    {
      image: arab,
      name: "Arabic",
    },
  ];

  const casestd = [
    {
      caseimg: c1,
      p1: "Easy claims",
      p2: "Submission",
    },
    {
      caseimg: c2,
      p1: "Faster",
      p2: "Collection",
    },
    {
      caseimg: c3,
      p1: "24x7 FAQ -",
      p2: "Customer support",
    },
    {
      caseimg: c4,
      p1: "Seamless",
      p2: "Transactions",
    },
    {
      caseimg: c5,
      p1: "Engaging push",
      p2: "Notifications",
    },
    {
      caseimg: c6,
      p1: "Accurate lead",
      p2: "Qualification",
    },
    {
      caseimg: c7,
      p1: "Proactive",
      p2: "Customer support",
    },
    {
      caseimg: c8,
      p1: "More upselling",
      p2: "And cross-selling",
    },
  ];
  const slidervids = [
    {
      vid: "https://www.youtube.com/embed/5LMfLovJNqk",
      text: "Callwise seamlessly navigates complex conversations with its cognitive Q&A technology.",
      heading: "Cognitive Q&A Capabilities",
    },
    {
      vid: "https://www.youtube.com/embed/ptzMFVfFpF8",
      text: "Equipped with Advanced Speech Recognition (ASR), Callwise ensures no detail goes unaddressed.",
      heading: "Real-Time Transcription",
    },
    {
      vid: "https://www.youtube.com/embed/DBzTlJ16kRw",
      text: "Voice Biometric technology identifies returning customers, fostering a personalized experience.",
      heading: "Voice Biometrics Recognition ",
    },
    {
      vid: "https://www.youtube.com/embed/TMgQ3HzKmNI",
      text: "Streamlines operations across all channels, ensuring an efficient customer communication strategy.",
      heading: "Omni-Channel Proficiency",
    },
  ];
  const tabs = [
    {
      name: "Finance",
    },
    {
      name: "insurance",
    },
    {
      name: "Ecommerce & Retail",
    },
    {
      name: "Logistics",
    },
    {
      name: "Restaurant Industry",
    },
  ];

  const financedata = [
    {
      name: "Courtesy pre-due reminders",
    },
    {
      name: "Transaction verification",
    },
    {
      name: "Customer Information collection",

    },
    {
      name: "Telemarketing of New Products",
    },
    {
      name: "Lead generation",
    },
  ];
  const insurancedata = [
    {
      name: "Application Status Updates",
    },
    {
      name: "Policy Renewals"
    },
    {
      name: "Premium Status Updates",
    },
    {
      name: "Claim Status Updates",
    },
    {
      name: "Payment Reminders",
    },
    {
      name: "Lead Generations",
    },
    {
      name: "Tri-aging Incoming Inquiries",
    },
    {
      name: "Customer Information Collection",
    },
  ];
  const ecommerence = [
    {
      name: "Order Confirmation",
    },
    {
      name: "Upselling",
    },
    {
      name: "Cart Abandon Reminders",
    },
    {
      name: "Payment Reminders",
    },
    {
      name: "Merchant Promotion Activation",
    },
    {
      name: "Cash On Delivery Confirmation",
    },
    {
      name: "Customer Information Collection",
    },
    {
      name: "Delivery Notification",
    },
    {
      name: "Back-In Stock Reminders",
    },
    {
      name: "Provide Warranty Information",
    },
    {
      name: "Renewal and Upgrade Alerts",
    },
    {
      name: "Review and Feedback Request",
    },
  ];
  const logistics = [
    {
      name: "Shipping Status Checks",
    },
    {
      name: "Cash on Delivery Alerts",
    },
    {
      name: "Delivery Rescheduling",
    },
    {
      name: "Order Status Check",
    },
    {
      name: "Cash on Delivery Notifications",
    },
    {
      name: "Order Amendments",
    },
    {
      name: "Collecting Feedback",
    },

    {
      name: "Customer Information Collection",
    },
    {
      name: "Provide Warranty Information",
    },
  ];
  const Restaurant = [
    {
      name: "Order Confirmation",
    },
    {
      name: "Delivery Updates",
    },
    {
      name: "Collecting Feedbacks",
    },
    {
      name: "Order Status Check",
    },
    {
      name: "Drive Through Automation",
    },
    {
      name: "Ai-Based Dish Recommendations",
    },
  ];
  const demoSlides1 = [
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/QfzXj_y6iDk?list=PLnYaRuqVJ4zXM2x4P6S8bZl0Qz2w6elEr",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/S8uQAEXmAOs?list=PLnYaRuqVJ4zXM2x4P6S8bZl0Qz2w6elEr",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/QbiyFJRhDrw?list=PLnYaRuqVJ4zXM2x4P6S8bZl0Qz2w6elEr",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/yRjFgHUDjbw?list=PLnYaRuqVJ4zXM2x4P6S8bZl0Qz2w6elEr",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/vDjXAFkp2jY?list=PLnYaRuqVJ4zXM2x4P6S8bZl0Qz2w6elEr",
    },
  ];
  const insviddata = [
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/UoHXYs-4ydw?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/30NXq3jWsXM?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/QEM5gWwwRok?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/8RcXk65qiH0?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/Hcn0K6NZnfo?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/09foL5kEe1Y?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/Wzfc9dPlq1I?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/jsIB0lJR3Bw?list=PLnYaRuqVJ4zXmXPQ00xRC_BTO36rq8d7a",
    },
  ];
  const ecommernceviddata = [
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/GgGr4m9wvxk?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW"
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/SoqIWKV30hg?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/qMIOpLPTYEU?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/wFS1B2TAyAo?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/vkUS9n_hXlY?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/FoNdPY_mlSA?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/K3ZoyxtoL2s?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/_IGD-BiiAcs?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/nUnlKXNiwJ0?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/tb7VNl3NVkY?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/ZgHXPFFO_aQ?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/tQ_OlDh9NVU?list=PLnYaRuqVJ4zWDco9J8pNC4gPOLsL7qbYW",
    },
  ];
  const logviddata = [
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/MdqQ8kKEOk4",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/qdNYepz_wwU?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/0UK-T1H8vOg?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/KGTDaHNkHmo?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/jM_gIHSf2p0?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/18EEOWU0nMQ",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/_YvqjSs3gmM?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/Hf1RgG-445U?list=PLnYaRuqVJ4zXZuElamomj__vHzSocbwNA",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/GCYFTEs60mQ",
    },
  ];
  const resturentviddata = [
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/TlzxtLZDUu8?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/vhwUK_4Mr4Y?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/_U-QBQM13mQ?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/4rG_OlxZg-U?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
    {
      img: leftmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/Zoc_TzqM85Y?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
    {
      img: rightmock,
      classSlider: "slider-for-c-d1",
      video: "https://www.youtube.com/embed/h9a3QiYHhgI?list=PLnYaRuqVJ4zUBvIAa7igbeynmD-0P-nNl",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleSlideClick = (index) => {
    navigate(location.pathname, { replace: true, search: '' });
    SetTabsMob(index);
    setActiveSlideIndex(0);
  };
  const sliderSettings = {
    className: "center zoom-center",
    centerMode: true,
    infinite: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    // centerPadding: "200px",
    beforeChange: (current, next) => {
      setActiveSlideIndex(next);
    },
    afterChange: (current) => {
      setActiveSlideIndex(current);
    },
    slidesToShow: 3,
    speed: 500,
    dots: true,
    accessibility: true,
    // autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600, // adjust this value based on your needs
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSlideChange = (index) => {
    setActiveSlideIndex(index);
    setAutoplayVideos(true);
  };
  const handleVideoEnded = () => {
    setAutoplayVideos(false);
  };
  const goToSlideIndex = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };
  const iframeRef = useRef(null);
  const LazyLoadIframe = ({ src, onEnded }) => {


    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {

            iframeRef.current.src = src;
            observer.unobserve(iframeRef.current);
          }
        });
      });


      observer.observe(iframeRef.current);


      return () => {
        observer.disconnect();
      };
    }, [src]);
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    country: '',
    desk: '',
    phoneNumber: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    // Reset error and status for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form is valid ')
      setShowModal(false);
      setshowModal3(true);
      const config = {
        SecureToken: '55c37aa3-9b8d-4773-9e02-be1801bb19d8',
        To: 'info@callwise.ai',
        From: "info@callwise.ai",
        Subject: 'Demo Scheduled',
        Body: `Name :${formData.name}, 
               Email: ${formData.email}, 
               Company: ${formData.company}, 
               Country: ${formData.country.value}, 
               Phone: ${formData.phoneNumber}, 
               What calls do you look forward to automate? : ${formData.desk}`
      }
      window.Email.send(config).then((e, c,  p, d) => console.log(e, c, p ,d))
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }

    return errors;
  };

  return (
    <>
      <div className="indusfinance-wrapper homebanner-p" id="financetop">
        <div className="finance-main-top-flex">
          <div className="finance-main-top-flex-left">
            <img src={contactcentre} alt=""/>
          </div>

          <div className="finance-main-top-flex-right">
            <h3>
              <span className="finance-spanheading">24/7 Contact Center!</span>
              <br /> Be the Business that Never Sleeps
            </h3>
            <p>
              With Callwise, you're not just adopting a solution, you're
              embracing a smarter way of doing business. Take the leap today and
              stay a step ahead with the future of customer interactions
            </p>

            <Button className="sad-idus" onClick={handleShowModal}>
              Schedule a demo
            </Button>
          </div>
        </div>
      </div>

      {/* slider mockup starts */}
      <div className="main-mob-mock-p">
        <div className="mockup-mob-wrapper">
          <div className="inbound-outbound-wrapper" id="industries">
            <h3>
              INDUSTRIES
            </h3>
            <p className="h-poi">
              By leveraging our services, you can automate up to <b>90%</b> of
              your customer interactions across voice chat channels.
            </p>
          </div>

          <div className="mockup-tabs">
            {tabs.map((data, index) => (
              <Button
                onClick={() => handleSlideClick(index)}
                className={
                  tabsMob == index ? "btn-mob-mock active" : "btn-mob-mock"
                }
              >
                {data.name}{" "}
              </Button>
            ))}
          </div>
          {tabsMob == 0 ? (
            <div className="slider-mocks-main-p" id="tab">
              <div className="slider-mocks-main-p-flex">
                <div className="mocks-main-p-flex-left">
                  <h4 className="f-animation">
                    Finance <span>Automation</span>
                  </h4>
                  {financedata.map((data, index) => (
                    <div className="ply-stp-fin" key={index}>
                      <img src={activeSlideIndex == index ? stop : play} alt=""/>
                      <p
                        className={
                          activeSlideIndex == index ? "red-cls-ac" : ""
                        }
                        onClick={() => goToSlideIndex(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="SliderF-wraper">
                  <div className="center-slider-main slider-main-desktop">
                    <div className="slider-main-app">
                      <img src={mobmock} className="mobmockup " alt=""/>
                      <Slider
                        {...sliderSettings}
                        ref={sliderRef}
                        afterChange={handleSlideChange}
                        dots={true}
                      >
                        {demoSlides1.map((slide, index) => (
                          <div className={slide.classSlider}>
                            <div
                              className={`slider-main-p ${index === activeSlideIndex
                                ? "active"
                                : "inactive"
                                }`}
                              key={slide.heading}
                            >
                              {index === activeSlideIndex ? (
                                <iframe
                                  ref={iframeRef}
                                  style={{ borderBottomLeftRadius: "36px", borderBottomRightRadius: '36px', marginTop: "38px", objectFit: "contain" }}
                                  width="215" height="415"
                                  className="fslider-video"
                                  src={slide.video}
                                  autoPlay={false}
                                  allowFullScreen='true'
                                  loop
                                  onEnded={handleVideoEnded}
                                />
                              ) : (
                                <img
                                  className="fslider-img"
                                  src={slide.img}
                                  alt="slider"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {tabsMob == 1 ? (
            <div className="slider-mocks-main-p" id="tab">
              <div className="slider-mocks-main-p-flex">
                <div className="mocks-main-p-flex-left">
                  <h4 className="f-animation">
                    Insurance <span>Automation</span>
                  </h4>
                  {insurancedata.map((data, index) => (
                    <div
                      className="ply-stp-fin"
                      key={index}
                    >
                      <img src={activeSlideIndex == index ? stop : play} alt=""/>
                      <p
                        className={
                          activeSlideIndex == index ? "red-cls-ac" : ""
                        }
                        onClick={() => goToSlideIndex(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="SliderF-wraper">
                  <div className="center-slider-main slider-main-desktop">
                    <div className="slider-main-app">
                      <img src={mobmock} className="mobmockup " alt=""/>
                      <Slider
                        {...sliderSettings}
                        ref={sliderRef}
                        afterChange={handleSlideChange}
                      >
                        {insviddata.map((slide, index) => (
                          <div className={slide.classSlider}>
                            <div
                              className={`slider-main-p ${index === activeSlideIndex
                                ? "active"
                                : "inactive"
                                }`}
                              key={slide.heading}
                            >
                              {index === activeSlideIndex ? (
                                <iframe
                                  ref={iframeRef}
                                  style={{ borderBottomLeftRadius: "36px", borderBottomRightRadius: '36px', marginTop: "38px", objectFit: "contain" }}
                                  width="215" height="415"
                                  className="fslider-video"
                                  src={slide.video}
                                  autoPlay={false}
                                  allowFullScreen
                                  loop
                                  onEnded={handleVideoEnded}
                                />
                              ) : (
                                <img
                                  className="fslider-img"
                                  src={slide.img}
                                  alt="slider"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {tabsMob == 2 ? (
            <div className="slider-mocks-main-p" id="tab">
              <div className="slider-mocks-main-p-flex">
                <div className="mocks-main-p-flex-left">
                  <h4 className="f-animation">
                    Ecommerce & Retail
                    <br /> <span>Automation</span>
                  </h4>
                  {ecommerence.map((data, index) => (
                    <div className="ply-stp-fin" key={index}>
                      <img src={activeSlideIndex == index ? stop : play} alt=""/>
                      <p
                        className={
                          activeSlideIndex == index ? "red-cls-ac" : ""
                        }
                        onClick={() => goToSlideIndex(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="SliderF-wraper">
                  <div className="center-slider-main slider-main-desktop">
                    <div className="slider-main-app">
                      <img src={mobmock} className="mobmockup " alt=""/>
                      <Slider
                        {...sliderSettings}
                        ref={sliderRef}
                        afterChange={handleSlideChange}
                      >
                        {ecommernceviddata.map((slide, index) => (
                          <div className={slide.classSlider}>
                            <div
                              className={`slider-main-p ${index === activeSlideIndex
                                ? "active"
                                : "inactive"
                                }`}
                              key={slide.heading}
                            >
                              {index === activeSlideIndex ? (
                                <iframe
                                  ref={iframeRef}
                                  style={{ borderBottomLeftRadius: "36px", borderBottomRightRadius: '36px', marginTop: "38px", objectFit: "contain" }}
                                  width="215" height="415"
                                  className="fslider-video"
                                  src={slide.video}
                                  autoPlay={false}
                                  allowFullScreen
                                  loop
                                  onEnded={handleVideoEnded}
                                />
                              ) : (
                                <img
                                  className="fslider-img"
                                  src={slide.img}
                                  alt="slider"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {tabsMob == 3 ? (
            <div className="slider-mocks-main-p" id="tab">
              <div className="slider-mocks-main-p-flex">
                <div className="mocks-main-p-flex-left">
                  <h4 className="f-animation">
                    Logistics & Supply chain <span>Automation</span>
                  </h4>
                  {logistics.map((data, index) => (
                    <div
                      className="ply-stp-fin"
                      key={index}
                    >
                      <img src={activeSlideIndex == index ? stop : play} alt=""/>
                      <p
                        className={
                          activeSlideIndex == index ? "red-cls-ac" : ""
                        }
                        onClick={() => goToSlideIndex(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="SliderF-wraper">
                  <div className="center-slider-main slider-main-desktop">
                    <div className="slider-main-app">
                      <img src={mobmock} className="mobmockup " alt=""/>
                      <Slider
                        {...sliderSettings}
                        ref={sliderRef}
                        afterChange={handleSlideChange}
                      >
                        {logviddata.map((slide, index) => (
                          <div className={slide.classSlider}>
                            <div
                              className={`slider-main-p ${index === activeSlideIndex
                                ? "active"
                                : "inactive"
                                }`}
                              key={slide.heading}
                            >
                              {index === activeSlideIndex ? (
                                <iframe
                                  ref={iframeRef}
                                  style={{ borderBottomLeftRadius: "36px", borderBottomRightRadius: '36px', marginTop: "38px", objectFit: "contain" }}
                                  width="215" height="415"
                                  className="fslider-video"
                                  src={slide.video}
                                  autoPlay={false}
                                  allowFullScreen
                                  loop
                                  onEnded={handleVideoEnded}
                                />
                              ) : (
                                <img
                                  className="fslider-img"
                                  src={slide.img}
                                  alt="slider"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {tabsMob == 4 ? (
            <div className="slider-mocks-main-p" id="tab">
              <div className="slider-mocks-main-p-flex">
                <div className="mocks-main-p-flex-left">
                  <h4 className="f-animation">
                    Restaurant <span>Automation</span>
                  </h4>
                  {Restaurant.map((data, index) => (
                    <div
                      className="ply-stp-fin"
                      key={index}>
                      <img src={activeSlideIndex == index ? stop : play} alt=""/>
                      <p
                        className={
                          activeSlideIndex == index ? "red-cls-ac" : ""
                        }
                        onClick={() => goToSlideIndex(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="SliderF-wraper">
                  <div className="center-slider-main slider-main-desktop">
                    <div className="slider-main-app">
                      <img src={mobmock} className="mobmockup " alt=""/>
                      <Slider
                        {...sliderSettings}
                        ref={sliderRef}
                        afterChange={handleSlideChange}
                      >
                        {resturentviddata.map((slide, index) => (
                          <div className={slide.classSlider}>
                            <div
                              className={`slider-main-p ${index === activeSlideIndex
                                ? "active"
                                : "inactive"
                                }`}
                              key={slide.heading}
                            >
                              {index === activeSlideIndex ? (
                                <iframe
                                  ref={iframeRef}
                                  style={{ borderBottomLeftRadius: "36px", borderBottomRightRadius: '36px', marginTop: "38px", objectFit: "contain" }}
                                  width="215" height="415"
                                  className="fslider-video"
                                  src={slide.video}
                                  autoPlay={false}
                                  allowFullScreen
                                  loop
                                  onEnded={handleVideoEnded}
                                />
                              ) : (
                                <img
                                  className="fslider-img"
                                  src={slide.img}
                                  alt="slider"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* slider mockup ends */}
      <div className="inbound-outbound-wrapper" id="automate-tab">
        <h3>
          Automate Inbound & OutBound Calls
        </h3>
        <p className="h-poi">
          Callwise supercharge both your inbound and outbound operations, from providing real-time call transcriptions to recognizing returning customers for personalized interactions.
        </p>

        <div className="inbound-outbound-main">
          <div className="bound-btn-flex">
            <Button
              onClick={() => SetCounter(0)}
              className={counter == 0 ? "inbound-btn active" : "inbound-btn"}
            >
              Inbound calls
            </Button>
            <Button
              onClick={() => SetCounter(1)}
              className={counter == 1 ? "outbound-btn active" : "outbound-btn"}
            >
              outbound calls
            </Button>
          </div>

          {counter == 0 && (
            <div className="outbound-images-flex">
              <img src={inbound1} alt="inbound" />
              <img src={inbound2} alt="inbound" />
              <img src={inbound3} alt="inbound" />
            </div>
          )}

          {counter == 1 && (
            <div className="outbound-images-flex">
              <img src={outbound1} alt="inbound" />
              <img src={outbound2} alt="inbound" />
              <img src={outbound3} alt="inbound" />
            </div>
          )}
        </div>
      </div>

      <div className="inbound-outbound-wrapper" id="video-tab">
        <h3>
          Inbound & OutBound INQUIRIES
        </h3>
        <p className="h-poi">
          Navigating the world of inbound and outbound calls can often feel like walking a tightrope. But with Callwise AI, you'll be juggling both with ease and efficiency!
        </p>

        <div className="indus-vid-sli">
          <Slider {...settings}>
            {slidervids.map((data, index) => (
              <div className="slider-vids-main" key={index}>
                <div className="vids-con">
                  <iframe
                    ref={iframeRef}
                    className="slider-vid-in"
                    src={data.vid}
                    autoPlay={false}
                    loop
                    allowfullscreen='true'
                    frameborder="0"
                    onEnded={handleVideoEnded}
                  />
                  <h5>{data.heading}</h5>
                  <p>{data.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="inbound-outbound-wrapper" id="endless-tab">
        <h3>
          Endless Possibilities With Callwise
        </h3>
        <p className="h-poi line-b-hr">
          Our AI Agent Assist doesn't just help with customer calls. Callwise's ASR as a Service transcribes your customer interactions in real-time, ensuring nothing is lost in communication. Perfect for training, compliance, and improving service quality.
        </p>

        <div className="casestd-grid">
          {casestd.map((data, index) => (
            <div className="case-std-chld" key={index}>
              <img src={data.caseimg} alt="casestudy" />
              <p>{data.p1}</p>
              <p>{data.p2}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="suppported-language-btn">
        <div className="inbound-outbound-wrapper line-b-hr-btn">
          <h3>SUPPORTED LANGUAGES</h3>
        </div>

        <div className="bg-supported-language casetd-grid">
          <div className="flex-languages">
            {language.map((data, index) => (
              <div className="flex-lang-inner" key={index}>
                <img src={data.image} alt=""/>
                <p>{data.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* modal */}
      <Modal
        className="modal-banner-wrapper"
        id='demo-modal'
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <img src={closemodal} onClick={handleCloseModal} alt=""/>
        </Modal.Header>
        <Modal.Body>
          <div className="modaldollor">
            <img className="sademo-img-modal" src={sademo} alt=""/>
            <h4>Do you want to </h4>
            <h3>Schedule a demo? </h3>
          </div>

          <form ref={form} onSubmit={handleSubmit}>
              {errors.name && <span className="error">{errors.name}</span>}
              <input
                placeholder='Name*'
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
              <input
                placeholder='Email*'
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.company && <span className="error">{errors.company}</span>}
              <input
                placeholder='Company Name'
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
              {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
              <div className="d-flex" >
              <CountrySelector setData={setFormData} data={formData} />
              <input
                placeholder='Phone Number*'
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                inputMode="numeric"
                 
                required
              />
              </div>
              
              {errors.desk && <span className="error">{errors.desk}</span>}
              <input
                placeholder='What type of calls are you looking forward to automate'
                type="text"
                name="desk"
                value={formData.desk}
                onChange={handleChange}
              />

              <div className="btn-mdl-wrapper">
                <Button className="btn-mdl-red" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </form>
        </Modal.Body>
      </Modal>
      <Modal show={showModal3} onHide={handleCloseModal3} className="submit-modal">
        <div className="submit-modal-content">
          <img src={thankyouvector} alt=""/>
          <h1>Thank you!</h1>
          <p>Your submission has been sent.</p>
        </div>
      </Modal>
    </>
  );
};
export default Finance;
