import React, { useState } from 'react'
import { InputSwitch } from "primereact/inputswitch";

import "primereact/resources/themes/lara-light-cyan/theme.css";
import PlanCard from './PlanCard';

import standard from '../../../images/CallTracking/standard.png'
import business from '../../../images/CallTracking/business.png'
import enterprise from '../../../images/CallTracking/enterprise.png'

const Plans = () => {
    const [checked, setChecked] = useState(false);
    return (
        <>
            <div className='plans-main'>
                <div className='plans-context'>
                    <div>
                        <h2>
                            Flexible Plans for Every Business Journey!
                        </h2>
                        <p>
                            Find your perfect fit with scalable plans. Maximize value at every stage of your growth.
                        </p>
                    </div>
                    <div className='plans-context-button'>
                        <p>
                            Monthly
                        </p>
                        <div>
                            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                        </div>
                        <p>
                            Yearly
                        </p>
                    </div>
                </div>

                {checked ? (
                    <div className='plansCard-main'>
                        <PlanCard
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$2250'
                            per='per year'
                            saving='Save $350'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$4450'
                            per='per year'
                            saving='Save $475'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                        <PlanCard
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='$8650'
                            per='per year'
                            saving='Save $650'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                    </div>
                ) : (
                    <div className='plansCard-main'>
                        <PlanCard
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$250'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$450'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                        <PlanCard
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='$550'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Plans
