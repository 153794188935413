import React from 'react'

const Terms = () => {
    return (
        <>
            <div className='privacy-banner'>
                <div className='privacy-heading'>
                    <h1> TERMS & CONDITIONS </h1>
                </div>
                <p>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</p>
                <h2>TERMS OF USE</h2>

                <p>Welcome to <a href='/'><span style={{ color: 'red' }}>callwise.ai</span></a>, a product of IDRAK Ai, LTD. (“us”, “we”, or “our”), a registered entity, having its registered office at 71-75 Shelton Street Covent Garden, London WC2H 9JQ, UK.</p>
                <p>This document is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing of the Rules and Regulations, Privacy Policy, and Terms and Conditions for access or usage of Websites.</p>
                <p>The terms and conditions as stipulated below constitute a legally binding and enforceable agreement between the Company and the User regarding the use of Services provided by the websites named callwise.ai and/or Applications and/or Email addresses (hereinafter referred to as “Platform”) owned by the Company.</p>
                <p>The User is urged to read the terms and conditions carefully before accessing the Services of the Platform. The agreement to the said terms unequivocally binds the User to all the terms and conditions and all existing Agreements of the Company. If you indulge in any activity in a manner that is inconsistent with these terms and conditions, the Company may terminate your current access and place a restriction on access in the future.</p>

                <h3>I. DEFINITIONS:</h3>
                <p>The following words shall have these meanings as defined herein below:</p>
                <p>Agreement: This shall mean and include the terms and conditions and other references drawn including Privacy Policy stated herein.</p>
                <p>Company: This term is defined  as Callwise.</p>
                <p>Services: This term is defined as access to various resources on the Platform including documentation and other product information, download areas, and other services, software, including developer tools and sample code, and Application Program Interface information.</p>
                <p>User/Users/you: This term is referred to any person visiting the Platform.</p>
                <p>User Information: This term is defined as anything User/you post, upload, share, store, or otherwise provide through the Services and/or communicate with through the Services.</p>
                <p>Platform: This term is defined as the websites named  Callwise.ai and/or Applications and/or Email Addresses of the Company and  Callwise</p>


                <h3>II. APPLICABLE LAWS AND JURISDICTION:</h3>
                <p>These Terms and Conditions will be governed by and construed in accordance with the laws of the United States of America. The courts in the State of Delaware, USA alone shall have exclusive jurisdiction over any dispute or issue arising out of the use of the services provided by the Platform. The User shall not assign these Terms or assign any rights or delegate any obligations hereunder, in whole or in part, whether voluntarily or by operation of law, without prior written consent from the Company, any such purported assignment or delegation will be null and void and of no force or effect.</p>

                <h3>III. ELIGIBILITY:</h3>
                <p>All Users who wish to avail of the Services provided by the Platform should be of 18 years of age and competent to enter into a legally binding contract and not disqualified by any existing law in force.</p>
                <p>Individuals below the age of 18 years, who wish to utilize the Services provided by the Platform, can do so, under the guidance and supervision of Parents or legal guardians under the registered account on the Platform.</p>


                <h3>IV. PRIVACY POLICY:</h3>
                <p>Please see the Privacy Policy relating to the collection and use of your information. By using the Platform and providing any information, you/Users are consenting to the processing of your information by us and consenting to our Privacy Policy.</p>


                <h3>V. PURPOSE:</h3>
                <p>The Company wishes to be the most accessible, affordable, and convenient Platform that allows developers, individuals, companies, and agencies to easily build, host, and manage Chatbots. Please read on to learn the rules and restrictions that govern your use of our websites, products, services, and applications.</p>


                <h3>VI. RIGHT OF THE COMPANY OVER USER INFORMATION:</h3>
                <p>By accepting these terms and conditions, the User agrees to authorize the Company to own all copyright and other Intellectual Property Rights over all the information provided by them.</p>
                <p>Anything that the Users post, upload, share, store, or otherwise provide through the Services and/or communicate with through the Services is their User Information. Please note that all of the licenses are subject to our Privacy Policy to the extent they relate to User Information and may also include their personally identifiable information.</p>
                <p>For all User Information, Users hereby grant us a license to translate, modify (for technical purposes, for example making sure the content is viewable on a mobile as well as a computer) and reproduce and otherwise act with respect to such User Information, in each case to enable us to operate the Services.</p>


                <h3>VII. OBLIGATIONS OF THE USERS:</h3>
                <p>The following are the obligations of the Users which need to be fulfilled while using the Services of the Platform.</p>
                <p>The User must provide complete and correct information regarding your name, residential address, age, email address, and other details as required by the Platform.</p>
                <p>The User must agree to review the terms and conditions from time to time which are subject to change at the complete discretion of the Company.</p>
                <p>The User must agree that all information provided will be subject to a verification process by the Company.</p>
                <p>The User must ensure compliance with the instructions given by the Company at all times while accessing the Platform.</p>
                <p>The User is responsible for all applicable taxes and costs which he/she/it might incur while assessing the Services of the Platform.</p>
                <p>The User will be solely responsible for all the information stored and transmitted by them and is responsible for any wrong information entered by him/her/it and will be liable for any action taken by a third party with regard to the same.</p>
                <p>The User must inform the Company immediately in case of any breach or unauthorized use of personal information.</p>
                <p>The User acknowledges that all information provided by him/her/it will be saved and used and commercially exploited by the Platform as deemed fit by the Company.</p>
                <p>The User must have the knowledge that by submitting any information to the Platform, he/she/it is granting the Company an irrevocable, non-cancellable, perpetual, worldwide, non-exclusive, royalty-free, sub-licensable right to exercise all copyright and publicity rights to use, reproduce, publish, distribute or perform and display such content on the Platform.</p>


                <h3>VIII. PROHIBITED ACTIONS OF THE USER:</h3>
                <p>The following actions of the User are strictly prohibited:</p>
                <p>The User must not use the information provided by the Platform for any unlawful or fraudulent purposes.</p>
                <p>The User must not infringe any Intellectual Property Rights of the Company or any person or entity listed on the Platform by copying, modifying, or distributing such content.</p>
                <p>The User must not attempt to reverse engineer or jeopardize the correct functioning of the Platform, or otherwise attempt to derive the source code of our Technology (including software, tools, methods, processes, and infrastructure) that enables or underlies the Platform.</p>
                <p>The User must not replicate the Services, in whole or in part, or use the Services to develop or offer a similar or competitive website, application, product, or service.</p>
                <p>The User must not interfere in any way with the proper functioning of the Platform or interfere with or disrupt any servers or networks connected to the Platform, or disobey any requirements, procedures, policies, or regulations of networks connected to the Platform.</p>
                <p>The User must not indulge in any activity or transaction which would pose a threat to the safety and security of the Platform.</p>
                <p>The User must refrain from any activity which would involve the transmission of a virus, worm, or other harmful component into the system of the Platform while accessing the services provided by the Platform.</p>
                <p>The User must not undertake to resell or assign his/her/its rights or obligations under these Terms and Conditions.</p>


                <h3>IX. ADVERTISEMENTS AND PROMOTIONS:</h3>
                <p>The Company may run advertisements and promotions from third parties on the Platform. User’s/Your correspondence or business dealings with, or participation in promotions of, advertisers other than the Company found on or through the Platform, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between User/you and such advertiser. The Company is not responsible or liable for any loss or damage of any sort incurred as a result of any such dealings or as a result of the presence of such advertisers on the Platform.</p>

                <h3>X. INTELLECTUAL PROPERTY RIGHTS:</h3>
                <p>The Service and its original content, features, and functionality are and will remain the exclusive property of the Company and its licensors. The Service is protected by copyright, trademark, and other laws. Our trademarks and trade dress shall not be used in connection with any product or service without the prior written consent of the Company.</p>
                <p>The User of the Platform has no right or claim to use, copy, reproduce, distribute, transmit, broadcast, or display any trademarks, trade names, service marks, logos, domain names, or any distinctive brand features of the Company.</p>
                <p>All such logos, trademarks, trade names, service marks, and brand names, including material, designs, and graphics belong exclusively to the Company, and no User can indulge in any activity which will amount to infringement of the Intellectual Property Rights.</p>


                <h3>XI. INDEMNITY:</h3>
                <p>The User agrees to indemnify and hold the Company, and its subsidiaries, affiliates, officers, agents, co-branders, or other partners, and employees, harmless from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of the content of what the User submits, posts, or transmits through the Service, through the User’s use of the Service, the User’s connection to the Service, the User’s violation of these Terms or Condition, or the User’s violation of any rights of another.</p>


                <h3>XII. CONFIDENTIALITY:</h3>
                <p>All aspects related to financial, commercial, operational, and technical information of the Company which is received by the User, have to be kept confidential and secret. The User is not allowed to disclose to any third party the confidential information of the Company, in whole or in part, except in cases where it is required and upon prior written permission from the Company. The User is not allowed to use, reproduce, transform, or store such confidential information without prior written permission of the Company. All confidential information voluntarily provided by the User to the Company is done at their own risk. If such information is misused and collected by a third party, the Company is not liable for such action.</p>



                <h3>XIII. MODIFICATIONS TO SERVICE:</h3>
                <p>The Company reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. The User agrees that the Company shall not be liable to the User or any third party for any modification, suspension, or discontinuance of the Service.</p>


                <h3>XIV. AMENDMENT:</h3>
                <p>The Company reserves its right to modify or terminate the Services provided by the Platform, for any reason, without prior notice, without liability to the User or any other members or third party. The Company also reserves the right to modify the terms from time to time without notice. The User agrees to regularly review the Terms and Conditions to keep himself/herself/itself apprised of any such changes.</p>


                <h3>XV. FORCE MAJEURE:</h3>
                <p>The Company shall not be liable or responsible for any failure to perform, or delay in performance of, any of its obligations under these Terms that is caused by events outside its reasonable control (“Force Majeure Event”).</p>
                <p>A Force Majeure Event includes any act, event, non-happening, omission, or accident beyond the Company’s reasonable control and includes, in particular (without limitation), the following:</p>
                <p>Strikes, lock-outs, or other industrial action;</p>
                <p>Civil commotion, riot, invasion, terrorist attack, or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</p>
                <p>Fire, explosion, storm, flood, earthquake, subsidence, or other natural disaster;</p>
                <p>Impossibility of the use of railways, shipping, aircraft, motor transport, or other means of public or private transport;</p>
                <p>Pandemic, Epidemic, and Quarantine/Lockdown Restriction;</p>
                <p>Impossibility of the use of public or private telecommunications networks; and The acts, decrees, legislation, regulations, or restrictions of any government.</p>
                <p>The Company’s performance under these Terms is deemed to be suspended for the period that the Force Majeure Event continues, and the Company shall have an extension of time for performance for the duration of that period. The Company will use its reasonable endeavors to bring the Force Majeure Event to a close or to find a solution by which its obligations under these Terms shall be performed despite the Force Majeure Event.</p>



                <h3>XVI. ENTIRE AGREEMENT:</h3>
                <p>These Terms and Conditions constitute the entire agreement between the Users and the Company with respect to the subject matter hereof, supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject.</p>

                <h3>XVII. CONTACT/NOTICE:</h3>
                <p>Any Complaints/Notices to the Company may be made via email or regular mail to the below-mentioned address:</p>
                <a href='/'><p style={{ color: 'red' }}>callwise.ai</p></a>
                <a href='https://maps.app.goo.gl/wGWevsEyeq6FoWsf7' target="_blank"><p style={{ color: 'red' }}>71-75 SHELTON STREET COVENT GARDEN, LONDON, UNITED KINGDOM WC2H 9JQ</p></a>
                <a href="mailto:info@callwise.ai" target="_blank"><p style={{ color: 'red' }}>info@callwise.ai</p></a>
                <a href='tel:+44 (7902) 751142'><p style={{ color: 'red' }}>+44 (7902) 751142</p></a>
            </div>
        </>
    )
}

export default Terms
